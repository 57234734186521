import { graphql, useStaticQuery } from "gatsby";

interface UseExhibitionCategoriesQuery {
  kontentTaxonomy: {
    terms: {
      codename: string;
      name: string;
    }[];
  };
}

export const useExhibitionCategories = () => {
  const exhibitionCatergoryData =
    useStaticQuery<UseExhibitionCategoriesQuery>(graphql`
      query ExhibitionCategoryQuery {
        kontentTaxonomy(system: { codename: { eq: "exhibition_category" } }) {
          terms {
            codename
            name
          }
        }
      }
    `);

  let categories = {};

  exhibitionCatergoryData?.kontentTaxonomy.terms.map(
    (term) => (categories[term.codename] = { name: term.name })
  );

  return categories;
};
