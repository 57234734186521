import React, { useState, useEffect } from "react";
import { UseDigitalExhibitions } from "../../../lib/useDigitalExhibitions";
import { ComponentProps } from "../../../types";
import { SectionWrapper } from "../../containers/SectionWrapper";
import ContentTiles from "../ContentTiles/ContentTiles";
import * as styles from "./DigitalExhibitionListing.module.css";

type SortOrder = "alpha" | "reverse" | "newest" | "oldest";

interface Props extends ComponentProps {
  exhibitions: UseDigitalExhibitions["digitalExhibitions"];
  categories: {
    [key: string]: {
      name: string;
      count?: number;
    };
  };
}

export const DigitalExhibitionListing: React.FC<Props> = ({
  exhibitions,
  categories,
  ...props
}) => {
  const categoryCodenames = Object.keys(categories).filter(
    (catCodename) =>
      categories[catCodename].count && categories[catCodename].count > 0
  );
  const [visibleExhibitions, setVisibleExhibitions] = useState(exhibitions);
  const [showAllCategories, setShowAllCategories] = useState(true);
  const [activeCategories, setActiveCategories] = useState(categoryCodenames);
  const totalCategories = categoryCodenames.length;
  const [sortOrder, setSortOrder] = useState("newest" as SortOrder);
  const [showCategoryFilters, setShowCategoryFilters] = useState(false);
  const [showDateFilters, setShowDateFilters] = useState(false);
  const [showNameFilters, setShowNameFilters] = useState(false);

  const handleCategoryClick = (codename: string) => {
    if (codename === "all") {
      setActiveCategories(categoryCodenames);
      setShowAllCategories(true);
      return;
    }

    let newActiveCategories = [codename];

    if (activeCategories.includes(codename) && !showAllCategories) {
      newActiveCategories = activeCategories.filter((cat) => cat !== codename);

      if (newActiveCategories.length === 0) {
        newActiveCategories = categoryCodenames;
      }
    }

    if (!activeCategories.includes(codename) && !showAllCategories) {
      newActiveCategories = [codename, ...activeCategories];
    }

    setShowAllCategories(newActiveCategories.length === totalCategories);
    setActiveCategories(newActiveCategories);
  };

  useEffect(() => {
    let newExhibitions = [] as UseDigitalExhibitions["digitalExhibitions"];

    exhibitions.forEach((exhibition) => {
      if (
        exhibition.exhibitionCategories.some((cat) =>
          activeCategories.includes(cat)
        )
      ) {
        exhibition.contentTile && newExhibitions.push(exhibition);
      }
    });

    setVisibleExhibitions(newExhibitions);
  }, [activeCategories]);

  useEffect(() => {
    const visibleExhCopy = [...visibleExhibitions];
    let newExhibitions = [] as UseDigitalExhibitions["digitalExhibitions"];

    if (sortOrder === "alpha" || sortOrder === "reverse") {
      newExhibitions = sortByName(sortOrder, visibleExhCopy);
    }

    if (sortOrder === "oldest" || sortOrder === "newest") {
      newExhibitions = sortByDate(sortOrder, visibleExhCopy);
    }

    setVisibleExhibitions(newExhibitions);
  }, [sortOrder]);

  const renderCategories = () => {
    return categoryCodenames.map((catCodename, idx) => {
      const categoryData = categories[catCodename];

      return (
        <button
          onClick={() => handleCategoryClick(catCodename)}
          key={`cat${idx}`}
          className={
            !showAllCategories && activeCategories.includes(catCodename)
              ? styles.selected
              : undefined
          }
        >
          {categoryData.name}
        </button>
      );
    });
  };

  const renderExhibitions = () => (
    <ContentTiles
      tileLayout="thirds"
      width="contained"
      contentTiles={visibleExhibitions.map((exh) => exh.contentTile)}
      id={""}
    />
  );

  return (
    <SectionWrapper {...props}>
      <div className={styles.digitalexhibitionlisting}>
        <div className={styles.filters}>
          <div className={styles.selectors + " contained-component"}>
            <div className={styles.filterLabel}>Filter by</div>
            <div>
              <button
                onClick={() => {
                  setShowDateFilters(!showDateFilters);
                  setShowCategoryFilters(false);
                  setShowNameFilters(false);
                }}
                className={
                  showDateFilters
                    ? styles.filterBtn + " " + styles.open
                    : styles.filterBtn
                }
              >
                Date
              </button>
              {showDateFilters && (
                <ul>
                  <li>
                    <button
                      onClick={() => {
                        setSortOrder("newest");
                        setShowDateFilters(false);
                      }}
                      className={
                        sortOrder === "newest"
                          ? styles.selectorBtn + " " + styles.selected
                          : styles.selectorBtn
                      }
                    >
                      Newest to Oldest
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => {
                        setSortOrder("oldest");
                        setShowDateFilters(false);
                      }}
                      className={
                        sortOrder === "oldest"
                          ? styles.selectorBtn + " " + styles.selected
                          : styles.selectorBtn
                      }
                    >
                      Oldest to Newest
                    </button>
                  </li>
                </ul>
              )}
            </div>
            <div>
              <button
                onClick={() => {
                  setShowNameFilters(!showNameFilters);
                  setShowCategoryFilters(false);
                  setShowDateFilters(false);
                }}
                className={
                  showNameFilters
                    ? styles.filterBtn + " " + styles.open
                    : styles.filterBtn
                }
              >
                Name
              </button>
              {showNameFilters && (
                <ul>
                  <li>
                    <button
                      onClick={() => {
                        setSortOrder("alpha");
                        setShowNameFilters(false);
                      }}
                      className={
                        sortOrder === "alpha"
                          ? styles.selectorBtn + " " + styles.selected
                          : styles.selectorBtn
                      }
                    >
                      A-Z
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => {
                        setSortOrder("reverse");
                        setShowNameFilters(false);
                      }}
                      className={
                        sortOrder === "reverse"
                          ? styles.selectorBtn + " " + styles.selected
                          : styles.selectorBtn
                      }
                    >
                      Z-A
                    </button>
                  </li>
                </ul>
              )}
            </div>
            <div>
              <button
                onClick={() => {
                  setShowCategoryFilters(!showCategoryFilters);
                  setShowDateFilters(false);
                  setShowNameFilters(false);
                }}
                className={
                  showCategoryFilters
                    ? styles.filterBtn + " " + styles.open
                    : styles.filterBtn
                }
              >
                Category
              </button>
            </div>
          </div>
          {showCategoryFilters && (
            <div className={styles.categoryFilters}>
              <button
                onClick={() => handleCategoryClick("all")}
                className={showAllCategories ? styles.selected : undefined}
              >
                All
              </button>
              {renderCategories()}
            </div>
          )}
        </div>
        {renderExhibitions()}
      </div>
    </SectionWrapper>
  );
};

const sortByName = (
  direction: "alpha" | "reverse",
  exhibitions: UseDigitalExhibitions["digitalExhibitions"]
) => {
  return exhibitions.sort((exhA, exhB) => {
    const aTitle = exhA.contentTile?.subhead || "";
    const bTitle = exhB.contentTile?.subhead || "";

    if (aTitle > bTitle) {
      return direction === "alpha" ? 1 : -1;
    }

    if (aTitle < bTitle) {
      return direction === "alpha" ? -1 : 1;
    }

    return 0;
  });
};

const sortByDate = (
  direction: "newest" | "oldest",
  exhibitions: UseDigitalExhibitions["digitalExhibitions"]
) => {
  return exhibitions.sort((exhA, exhB) => {
    const aDate = exhA.page?.displayPublishDate || 0;
    const bDate = exhB.page?.displayPublishDate || 0;

    if (aDate > bDate) {
      return direction === "newest" ? -1 : 1;
    }

    if (bDate > aDate) {
      return direction === "newest" ? 1 : -1;
    }

    return 0;
  });
};
