import { graphql, useStaticQuery } from "gatsby";
import { ContentTileProps } from "../components/elements/ContentTile/ContentTile";
import {
  CollectionPage,
  KontentLinkedContentTile,
  StringValue,
} from "../types";
import { formatContentTiles } from "./contentTiles";

export interface DigitalExhibition {
  page?: {
    url?: string;
    title?: string;
    displayPublishDate?: string;
  };
  systemId: string;
  contentTile?: ContentTileProps;
  exhibitionCategories: string[];
}

export interface UseDigitalExhibitions {
  digitalExhibitions: DigitalExhibition[];
  // pages: CollectionPage[];
}

interface ExhibitionQuery {
  allKontentItemContentDigitalExhibition: {
    nodes: {
      system: {
        id: string;
      };
      elements: {
        page: {
          value: {
            id: string;
            elements: {
              display_publish_date: StringValue;
              title: StringValue;
              url: StringValue & {
                url: string;
              };
            };
          };
        };
        exhibition_category: {
          value: {
            codename: string;
            name: string;
          }[];
        };
        content_tile: KontentLinkedContentTile;
      };
    }[];
  };
  // allPaginatedCollection: {
  // 	nodes: {
  // 		pages: CollectionPage[];
  // 		pageCount: number;
  // 	}[];
  // };
}

export const useDigitalExhibitions = (): UseDigitalExhibitions => {
  const digitalExhibitionsData = useStaticQuery<ExhibitionQuery>(graphql`
    query DigitalExhibitionQuery {
      allKontentItemContentDigitalExhibition {
        nodes {
          system {
            id
          }
          elements {
            page {
              value {
                ... on kontent_item_page {
                  id
                  elements {
                    display_publish_date {
                      value(formatString: "X")
                    }
                    url {
                      url
                    }
                    title {
                      value
                    }
                  }
                }
              }
            }
            exhibition_category {
              value {
                codename
                name
              }
            }
            content_tile {
              value {
                ...kontentContentTile
              }
            }
          }
        }
      }
    }
  `);

  if (
    digitalExhibitionsData.allKontentItemContentDigitalExhibition.nodes
      .length === 0
  ) {
    return { digitalExhibitions: [] };
  }

  const digitalExhibitions =
    digitalExhibitionsData.allKontentItemContentDigitalExhibition?.nodes.map(
      (exhibition, idx) => {
        const pageData = exhibition.elements?.page.value[0]?.elements;
        const contentTileData = exhibition.elements?.content_tile.value;
        const exhibitionCategories =
          exhibition.elements?.exhibition_category.value?.map(
            (category) => category.codename
          );

        const pageInfo = {
          url: pageData.url?.url,
          title: pageData.title?.value,
          displayPublishDate: pageData.display_publish_date?.value,
        };

        const contentTiles =
          contentTileData && formatContentTiles(contentTileData);

        return {
          systemId: exhibition.system?.id,
          page: pageInfo,
          contentTile: contentTiles && contentTiles[0],
          exhibitionCategories: exhibitionCategories,
        };
      }
    );

  return {
    digitalExhibitions,
  };
};
