import React from "react";
import { graphql } from "gatsby";
import { DigitalExhibitionListing } from "./DigitalExhibitionListing";
import { useDigitalExhibitions } from "../../../lib/useDigitalExhibitions";
import { useExhibitionCategories } from "../../../lib/useExhibitionCategories";
import { KontentComponentSettings } from "../../../types";
import { getMultiChoice } from "../../../lib/multichoice";

interface Props extends KontentComponentSettings {}

const DigitalExhibitionListingContainer: React.FC<Props> = (props) => {
  const exhibitions = useDigitalExhibitions().digitalExhibitions;
  const categories = useExhibitionCategories();

  exhibitions.forEach((exhibition) => {
    exhibition.exhibitionCategories.forEach((category) =>
      categories[category].count
        ? (categories[category].count += 1)
        : (categories[category].count = 1)
    );
  });

  return (
    <DigitalExhibitionListing
      exhibitions={exhibitions}
      categories={categories}
      anchor={props.component_settings__anchor_name.value}
      hideOnMobile={
        getMultiChoice(props.component_settings__hide_on_mobile) === "yes"
      }
      id={props.id}
    />
  );
};

export default DigitalExhibitionListingContainer;

export const fragmentDigitalExhibitionListingContainer = graphql`
  fragment DigitalExhibitionListing on kontent_item_component___digital_exhibition_listing {
    elements {
      component_settings__anchor_name {
        value
      }
      component_settings__hide_on_mobile {
        value {
          codename
        }
      }
    }
  }
`;
